























































































































































































































































































































































































































































































































































































































































































.item-product {
  height: 80px;
}

.fds-upload {
  .el-upload {
    width: 100%;
  }

  .el-upload-dragger {
    width: 100%;
    height: 195px;

    .el-icon-upload {
      margin-top: 10px;
    }
  }

  .fds-title {
    font-weight: bold;
  }

  .el-upload__tip {
    line-height: 1.2;
    margin-top: 0;
  }
}

.error-warning {
  .el-form-item__error {
    color: #6b6b6b;
  }

  .el-form-item.is-error .el-input__inner {
    border-color: #ffb848;
  }
}
